export function initHeader() {
    document.addEventListener('DOMContentLoaded', () => {
        init();
    });
}

function init() {
    // Header Search Dropdown
    $("header .search input").focus(function () {
        $('html').addClass("header-search--open").addClass("noscroll").addClass("overlay");
        $('.nav--search .bttn--close').attr('aria-expanded', "true");
    });

    // Header search close
    $("header .nav--search .bttn--close").click(function () {
        $('html').removeClass("header-search--open").removeClass("noscroll").removeClass("overlay");
    });

    // Mobile Header Search Open
    $("header .site__header__left .nav--search a").click(function (e) {
        $('html').addClass("header-search--open").addClass("noscroll").addClass("overlay");
        $('.nav--search .bttn--close').attr('aria-expanded', "true");
        e.preventDefault();
    });
    
    // Submenu > sub-submenu visibility
    // $('.menuSlidein--subMenu .nav-one a').on('click', function(e){
    //     const nodeId = this.dataset.nodeId;
    //     const $newSubmenu = $(this).closest('.menuSlidein--subMenu').find(`.nav-four[data-parent-id="${nodeId}"]`)
    //     $(this).parent().siblings().removeClass('active');
    //     if ($newSubmenu.length) {
    //         e.preventDefault();
    //         $(this).parent().addClass('active')
    //         $newSubmenu.removeClass('d-none').siblings().addClass('d-none');
    //     } else {
    //         $(this).closest('.menuSlidein--subMenu').find('.nav-four').addClass('d-none');
    //     }
    // });

    // Submenu > sub-submenu visibility on hover on desktop

    $('.menuSlidein--subMenu .nav-one li.has-ul > a').mouseenter(function() {
        const nodeId = $(this).data('nodeId');
        const $newSubmenu = $(this).closest('.menuSlidein--subMenu').find(`.nav-four[data-parent-id="${nodeId}"]`);
        if ($newSubmenu.length) {
            // Remove hover class from all .nav-four elements
            $('.nav-four').removeClass('hover');
            // Add hover class to the targeted .nav-four
            $newSubmenu.addClass('hover');
            // Add hover class to its parent li.has-ul
            $('li.has-ul').removeClass('hover');
            $(this).parent('li.has-ul').addClass('hover');
        }
    }).click(function() {
        // Toggle active class
        $(this).toggleClass('active');
        // Remove active class from other elements
        $('.menuSlidein--subMenu .nav-one li.has-ul > a').not(this).removeClass('active');

        // Check viewport width and prevent default if below 767 pixels
        if ($(window).width() <= 767) {
            event.preventDefault();
        }
    });
    
    // Handler for .menuSlidein--subMenu .nav-one li:not(.has-ul) > a
    $('.menuSlidein--subMenu .nav-one li:not(.has-ul) > a').mouseenter(function() {
        // Remove hover class from all .nav-four elements
        $('li.has-ul').removeClass('hover');
        $('.nav-four').removeClass('hover');
    });
    
    if (document.body.classList.contains('logged-out')) {
        fetch('/template/header-info').then(response => response.json()).then(data => {
            if (data.success) {
                if (data.data.cartItemsCount > 0) {
                    $('header.site__header .nav--cart span').text(data.data.cartItemsCount).closest('.nav--cart').addClass('active');
                }
                if (data.data.wishlistItemsCount > 0) {
                    const $nav = $('header.site__header .nav--favourites a');
                    $nav.find('span').text(data.data.wishlistItemsCount).removeClass('d-none');
                    $nav.attr('aria-label', (data.data.wishlistItemsCount > 1 ? $nav.attr('data-label-plural') : $nav.attr('data-label-singular')).replace('%d', data.data.wishlistItemsCount));
                }
            }
        });
    }
}